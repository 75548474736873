<!--@Time : 2022/2/8 17:42-->
<!--@Author : WangHaoRan-->
<!--@Annotation :-->
<template>
    <div>
        <v-row>
            <v-col cols="12" md="12" lg="12">
                <p
                    class="
                        text-h5
                        font-weight-black
                        text-uppercase
                        d-flex
                        align-center
                    "
                >
                    <v-icon large color="primary" class="mr-2"
                        >mdi-account-cog</v-icon
                    >
                    Settings
                </p>
                <v-divider></v-divider>
            </v-col>
        </v-row>
        <v-alert
            text
            dense
            color="primary"
            icon="mdi-account-alert"
            border="left"
            class="mt-3"
        >
            Password change is mandatory upon your first login. Please update
            the default password with your own and login again.
        </v-alert>
        <v-row>
            <v-col cols="4" md="4" lg="4">
                <v-text-field
                    v-model="oldPassword"
                    label="Old Password"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                    name="input-10-1"
                    counter
                    :disabled="change"
                    @click:append="show1 = !show1"
                ></v-text-field>
            </v-col>
            <v-col cols="4" md="4" lg="4">
                <v-text-field
                    v-model="newPassword1"
                    label="New Password"
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show2 ? 'text' : 'password'"
                    name="input-10-1"
                    counter
                    :disabled="change"
                    @click:append="show2 = !show2"
                ></v-text-field>
            </v-col>
            <v-col cols="4" md="4" lg="4">
                <v-text-field
                    v-model="newPassword2"
                    label="Confirm New Password"
                    :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show3 ? 'text' : 'password'"
                    :rules="[rules.match]"
                    name="input-10-1"
                    counter
                    :disabled="change"
                    @click:append="show3 = !show3"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-btn
            v-if="change"
            small
            color="primary"
            outlined
            @click="change = false"
            >Change Password</v-btn
        >
        <div v-else class="text-right mt-3">
            <v-btn
                min-width="100px"
                class="mr-3"
                color="primary"
                outlined
                small
                @click="change = true"
                >Cancel</v-btn
            >
            <v-btn
                min-width="100px"
                color="primary"
                small
                @click="changePassword"
                >Confirm</v-btn
            >
        </div>
    </div>
</template>

<script>
import { changeUserPassword, updateUserPatch } from "@/api/solar/user";
export default {
    name: "Settings",
    data() {
        return {
            edit: false,
            avatar: null,
            name: "",
            phoneNumber: "",
            email: "",
            oldPassword: null,
            newPassword1: null,
            newPassword2: null,
            show1: false,
            show2: false,
            show3: false,
            disabled: false,
            change: true,
            rules: {
                value: (v) =>
                    !v ||
                    v.size < 2000000 ||
                    "Avatar size should be less than 2 MB!",
                match: (v) =>
                    v === this.newPassword1 || "The two passwords do not match",
            },
        };
    },
    created() {},
    beforeDestroy() {
        // password_expired是true 改过密码
        if (!this.$store.getters.password_expired) {
            this.$router.push({ name: "Settings" });
        }
    },
    methods: {
        changeUser() {
            const FormData = new window.FormData();
            FormData.append("username", this.name);
            FormData.append("telephone", this.phoneNumber);
            FormData.append("email", this.email);
            updateUserPatch(this.$store.getters.user_id, FormData).then(
                () => {}
            );
        },
        changePassword() {
            if (
                (this.newPassword1 === this.newPassword2 &&
                    this.newPassword1 !== null) ||
                ("" && this.newPassword2 !== null) ||
                ""
            ) {
                this.change = true;
                changeUserPassword(this.$store.getters.user_id, {
                    password1: this.newPassword1,
                    password2: this.newPassword2,
                }).then(() => {
                    this.$store.dispatch("LogOut");
                });
            }
        },
    },
};
</script>

<style scoped>
</style>